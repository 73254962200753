import appComponents from '@/router/components';

const routes = [
    {
        path: '/products/element-pack',
        name: 'products.element.pack',
        components: {
            AuthHeader: appComponents.access_control.layouts.auth_header,
            SidebarLeft: appComponents.access_control.layouts.sidebar_left,
            Content: appComponents.products.elementPack,
        },
        meta: {
            title: 'Element Pack',
            required_auth:true,
            required_permission:true,
            permissions:['view_client_data']
        },
    },

    {
        path: '/products/pixel-gallery',
        name: 'products.pixel.gallery',
        components: {
            AuthHeader: appComponents.access_control.layouts.auth_header,
            SidebarLeft: appComponents.access_control.layouts.sidebar_left,
            Content: appComponents.products.pixelGallery,
        },
        meta: {
            title: 'Pixel Gallery',
            required_auth:true,
            required_permission:true,
            permissions:['view_client_data']
        },
    },
    {
        path: '/products/prime-slider',
        name: 'products.prime.slider',
        components: {
            AuthHeader: appComponents.access_control.layouts.auth_header,
            SidebarLeft: appComponents.access_control.layouts.sidebar_left,
            Content: appComponents.products.primeSlider,
        }, meta: {
            title: 'Prime Slider',
            required_auth:true,
            required_permission:true,
            permissions:['view_client_data']
        },
    },

    {
        path: '/products/ultimate-post-kit',
        name: 'products.ultimate.post.kit',
        components: {
            AuthHeader: appComponents.access_control.layouts.auth_header,
            SidebarLeft: appComponents.access_control.layouts.sidebar_left,
            Content: appComponents.products.ultimatePostKit,
        }, meta: {
            title: 'Ultimate Post Kit',
            required_auth:true,
            required_permission:true,
            permissions:['view_client_data']
        },
    },
    {
        path: '/products/ultimate-store-kit',
        name: 'products.ultimate.store.kit',
        components: {
            AuthHeader: appComponents.access_control.layouts.auth_header,
            SidebarLeft: appComponents.access_control.layouts.sidebar_left,
            Content: appComponents.products.ultimateStoreKit,
        },
        meta: {
            title: 'Ultimate Store Kit',
            required_auth:true,
            required_permission:true,
            permissions:['view_client_data']
        },
    },

    {
        path: '/products/zoloblocks',
        name: 'products.zoloblocks',
        components: {
            AuthHeader: appComponents.access_control.layouts.auth_header,
            SidebarLeft: appComponents.access_control.layouts.sidebar_left,
            Content: appComponents.products.zoloblocks,
        },
        meta: {
            title: 'Zoloblocks',
            required_auth:true,
            required_permission:true,
            permissions:['view_client_data']
        },
    },

    {
        path: '/products/:product/:license',
        name: 'products.view.domains',
        components: {
            AuthHeader: appComponents.access_control.layouts.auth_header,
            SidebarLeft: appComponents.access_control.layouts.sidebar_left,
            Content: appComponents.products.domains,
        },
        meta: {
            title: 'Product License Details',
            required_auth:true,
            required_permission:true,
            permissions:['view_client_data']
        },
    },

    {
        path: '/products/:product/downloads/files',
        name: 'products.view.download.files',
        components: {
            AuthHeader: appComponents.access_control.layouts.auth_header,
            SidebarLeft: appComponents.access_control.layouts.sidebar_left,
            Content: appComponents.products.downloads,
        },
        meta: {
            title: 'Product Download Files',
            required_auth:true,
            required_permission:true,
            permissions:['view_client_data']
        },
    },

    {
        path: '/products/:product/email-verification',
        name: 'products.email.verification',
        components: {
            AuthHeader: appComponents.access_control.layouts.auth_header,
            SidebarLeft: appComponents.access_control.layouts.sidebar_left,
            Content: appComponents.products.verifyProductEmail,
        },
        meta: {
            title: 'Product Email Verification',
            required_auth:true,
            required_permission:true,
            permissions:['view_client_data']
        },
    },


    {
        path: '/payment-histories',
        name: 'client.payment.histories',
        components: {
            AuthHeader: appComponents.access_control.layouts.auth_header,
            SidebarLeft: appComponents.access_control.layouts.sidebar_left,
            Content: appComponents.connected_accounts.payments,
        },
        meta: {
            title: 'Payment History',
            required_auth:true,
            required_permission:true,
            permissions:['view_client_data']
        },
    },

    {
        path: '/admin/settings/download-items',
        name: 'admin.settings.download.items',
        components: {
            AuthHeader: appComponents.access_control.layouts.auth_header,
            SidebarLeft: appComponents.access_control.layouts.sidebar_left,
            Content: appComponents.settings.downloadItems,
        },
        meta: {
            title: 'Download Item History',
            required_auth:true,
            required_permission:true,
            permissions:['view_download_item']
        },
    },

    {
        path: '/admin/settings/redemptions',
        name: 'admin.settings.redemptions',
        components: {
            AuthHeader: appComponents.access_control.layouts.auth_header,
            SidebarLeft: appComponents.access_control.layouts.sidebar_left,
            Content: appComponents.settings.redemptions,
        },
        meta: {
            title: 'Redemptions',
            required_auth:true,
            required_permission:true,
            permissions:['view_redemptions']
        },
    },

    {
        path: '/admin/settings/promotional/benefits',
        name: 'admin.settings.promotional.benefits',
        components: {
            AuthHeader: appComponents.access_control.layouts.auth_header,
            SidebarLeft: appComponents.access_control.layouts.sidebar_left,
            Content: appComponents.settings.promotionalBenefits,
        },
        meta: {
            title: 'Benefits',
            required_auth:true,
            required_permission:true,
            permissions:['view_promotional_benefit']
        },
    },


    {
        path: '/admin/settings/refund-requests',
        name: 'admin.settings.refund.payments',
        components: {
            AuthHeader: appComponents.access_control.layouts.auth_header,
            SidebarLeft: appComponents.access_control.layouts.sidebar_left,
            Content: appComponents.settings.refunds,
        },
        meta: {
            title: 'Refund Requests',
            required_auth:true,
            required_permission:true,
            permissions:['view_refund_requests']
        },
    },

    {
        path: '/admin/settings/client-banned-hosts',
        name: 'admin.settings.client.banned.host',
        components: {
            AuthHeader: appComponents.access_control.layouts.auth_header,
            SidebarLeft: appComponents.access_control.layouts.sidebar_left,
            Content: appComponents.settings.clientBannedHost,
        },
        meta: {
            title: 'Client Banned Hosts',
            required_auth:true,
            required_permission:true,
            permissions:['view_client_banned_hosts']
        },
    },

    {
        path: '/admin/settings/elite-licenses',
        name: 'admin.settings.elite.licenses',
        components: {
            AuthHeader: appComponents.access_control.layouts.auth_header,
            SidebarLeft: appComponents.access_control.layouts.sidebar_left,
            Content: appComponents.settings.eliteLicenses,
        },
        meta: {
            title: 'Licenses',
            required_auth:true,
            required_permission:true,
            permissions:['view_client_licenses','search_client_licenses']
        },
    },

    {
        path: '/admin/settings/schedules',
        name: 'admin.settings.corn.schedules',
        components: {
            AuthHeader: appComponents.access_control.layouts.auth_header,
            SidebarLeft: appComponents.access_control.layouts.sidebar_left,
            Content: appComponents.settings.schedules,
        },
        meta: {
            title: 'Schedules',
            required_auth:true,
            required_permission:true,
            permissions:['view_schedule_commands']
        },
    },

    {
        path: '/profile',
        name: 'profile',
        components: {
            AuthHeader: appComponents.access_control.layouts.auth_header,
            SidebarLeft: appComponents.access_control.layouts.sidebar_left,
            Content: appComponents.connected_accounts.profile,
        }, meta: {
            title: 'Profile',
            required_auth:true,
            required_permission:false,
            permissions:[]
        },
    },

    {
        path: '/refund-requests',
        name: 'refund.requests',
        components: {
            AuthHeader: appComponents.access_control.layouts.auth_header,
            SidebarLeft: appComponents.access_control.layouts.sidebar_left,
            Content: appComponents.refund_requests.index,
        },
        meta: {
            title: 'Refund Payments',
            required_auth:true,
            required_permission:true,
            permissions:['view_client_data']
        },
    },

    {
        path: '/payment-profile',
        name: 'payment.profile',
        components: {
            AuthHeader: appComponents.access_control.layouts.auth_header,
            SidebarLeft: appComponents.access_control.layouts.sidebar_left,
            Content: appComponents.connected_accounts.paymentProfile,
        },
        meta: {
            title: 'Payment Profile',
            required_auth:true,
            required_permission:true,
            permissions:['view_client_data']
        },
    },


    {
        path: '/change-password',
        name: 'change.password',
        components: {
            AuthHeader: appComponents.access_control.layouts.auth_header,
            SidebarLeft: appComponents.access_control.layouts.sidebar_left,
            Content: appComponents.connected_accounts.changePassword,
        },
        meta: {
            title: 'Change Password',
            required_auth:true,
            required_permission:false,
            permissions:[]
        },
    },

    {
        path: '/activity-log',
        name: 'auth.activity_log',
        components: {
            AuthHeader: appComponents.access_control.layouts.auth_header,
            SidebarLeft: appComponents.access_control.layouts.sidebar_left,
            Content: appComponents.connected_accounts.activityLog,
        },
        meta: {
            title: 'Activity Log',
            required_auth:true,
            required_permission:true,
            permissions:['view_client_data']
        },
    },

    {
        path: '/redeem-code',
        name: 'redeem.code',
        components: {
            AuthHeader: appComponents.access_control.layouts.auth_header,
            SidebarLeft: appComponents.access_control.layouts.sidebar_left,
            Content: appComponents.connected_accounts.redeemCode,
        },
        meta: {
            title: 'Redeem Code',
            required_auth:true,
            required_permission:false,
            permissions:[]
        },
    },

    {
        path: '/free-license',
        name: 'free.license',
        components: {
            AuthHeader: appComponents.access_control.layouts.auth_header,
            SidebarLeft: appComponents.access_control.layouts.sidebar_left,
            Content: appComponents.promotions.freeLicense,
        },
        meta: {
            title: 'Free License',
            required_auth:true,
            required_permission:false,
            permissions:[]
        },
    },

    {
        path: '/benefits',
        name: 'benefits',
        components: {
            AuthHeader: appComponents.access_control.layouts.auth_header,
            SidebarLeft: appComponents.access_control.layouts.sidebar_left,
            Content: appComponents.promotions.benefits,
        },
        meta: {
            title: 'Benefits',
            required_auth:true,
            required_permission:false,
            permissions:[]
        },
    },
];

export default routes;

